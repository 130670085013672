<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Drone: RLA</span>
    </v-card-title>

    <v-card-text>
      <v-list class="pb-0">
        <v-list-item
          v-for="(drone, index) in drones"
          :key="index"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-4' : ''}`"
        >
          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <div class="font-weight-semibold text-caption">
                <span class="text--primary me-1">{{ drone.aircraft_type.name }}</span>
              </div>

              <v-list-item-subtitle class="text-xs">
                {{ `SN: ${drone.serial_number}` }}
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div>
              <h4 :class="`font-weight-semibold text-caption ${drone.rla_status}--text`">
                {{ drone.rla_expiry }}
              </h4>
              <span :class="`text-xs ${drone.status_color}--text`">{{drone.status}}</span>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from "@mdi/js";
import Request from "@/core/api/request";

export default {
  data() {
    return {
      drones: [],
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      console.log("RLA");
      const { data, status } = await Request.get(`${this.$IYA.URI.AIRCRAFT__DASH}`);
      if (status !== this.$IYA.HTTP.OK) {
        return;
      }
      if (data.data.length < 1) {
        return;
      }
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.drones = data.data;
    },
  },
};
</script>
