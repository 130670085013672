<template>
  <v-card>
    <v-card-title>
      Expiry Dates
      <v-spacer></v-spacer>
      <div class="text-center"></div>
    </v-card-title>
    <v-card-text>
      <v-alert text color="info">
        <v-row align="center" no-gutters>
          <v-col cols="12" class="grow">
            <v-chip class="ma-2" color="success" label text-color="white">
              Safe
            </v-chip>
            When the license is still in a good state
          </v-col>
          <v-col cols="12" class="grow">
            <v-chip class="ma-2" color="error" label text-color="white">
              Expired
            </v-chip>
            When the license has expired
          </v-col>
          <v-col cols="12" class="grow">
            <v-chip class="ma-2" color="warning" label text-color="white">
              Warning
            </v-chip>
            When the license has 30 or less days before expiring
          </v-col>
        </v-row>
      </v-alert>

      <v-data-table :headers="headers" :items="usreList" class="table-rounded" hide-default-footer disable-sort>
        <template #[`item.pilot_license_expiry`]="{item}">
          <span :class="`${item.pilot_status}--text font-weight-medium`">
            {{ item.pilot_license_expiry }}
          </span>
        </template>
        <template #[`item.medical`]="{item}">
          <span :class="`${item.medical_status}--text font-weight-medium`">
            {{ item.medical }}
          </span>
        </template>
        <template #[`item.avsec`]="{item}">
          <span :class="`${item.avsec_status}--text font-weight-medium`">
            {{ item.avsec }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Request from "@/core/api/request";

export default {
  data() {
    return {
      headers: [
        { text: "NO.", value: "index", sortable: true },
        { text: "First Name", value: "first_name", sortable: false },
        { text: "Last Name", value: "last_name", sortable: false },
        { text: "Pilot License Expiry", value: "pilot_license_expiry", sortable: false },
        { text: "Medical", value: "medical", sortable: false },
        { text: "Avsec", value: "avsec", sortable: false },
      ],
      usreList: [],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      console.log("Expiry Dates");
      const { data, status } = await Request.get(`${this.$IYA.URI.USER__MED__DATES}`);
      if (status !== this.$IYA.HTTP.OK) {
        this.loading = false;

        return;
      }
      if (data.data.length < 1) {
        this.loading = false;

        return;
      }
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.usreList = data.data;
      this.loading = false;
    },
  },
};
</script>
