<template>
  <v-card>
    <div class="d-flex flex-sm-row flex-column">
      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3">Drone Maintenance</span>
        </v-card-title>

        <v-card-text>
          <v-list class="pb-0">
            <v-list-item
              v-for="(drone, index) in drones"
              :key="index"
              :class="`d-flex align-center px-0 ${index > 0 ? 'mt-4' : ''}`"
            >
              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-2">
                  <div class="font-weight-semibold text-caption">
                    <span class="text--primary me-1">{{ drone.aircraft.aircraft_type.name }}</span>
                  </div>

                  <v-list-item-subtitle class="text-xs">
                    {{ `SN: ${drone.aircraft.serial_number}` }}
                  </v-list-item-subtitle>
                </div>

                <v-spacer></v-spacer>

                <div>
                  <h4 :class="`font-weight-semibold text-caption ${drone.date_color}--text`">
                    {{ drone.due_date }}
                  </h4>
                  <span :class="`text-xs ${drone.date_color}--text`">{{ `Due Date` }}</span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>

      <v-divider class="my-sm-5 mx-5" :vertical="$vuetify.breakpoint.smAndUp"></v-divider>

      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3">Drone Batteries</span>
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(battery, index) in batteries"
              :key="index"
              :class="`d-flex align-center px-0 ${index > 0 ? 'mt-4' : ''}`"
            >
              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-2">
                  <div class="font-weight-semibold text-caption">
                    <span class="text--primary me-1">{{ `AN:/ ${battery.asset_number}` }}</span>
                  </div>

                  <v-list-item-subtitle class="text-xs">
                    {{ `SN/: ${battery.serial_number}` }}
                  </v-list-item-subtitle>
                </div>

                <v-spacer></v-spacer>

                <div>
                  <span :class="`font-weight-semibold text-caption ${battery.status_color}--text`">{{
                    battery.status
                  }}</span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from "@mdi/js";
import Request from "@/core/api/request";

export default {
  data() {
    return {
      drones: [],
      batteries: [],
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    };
  },
  created() {
    this.maintenance();
    this.battery();
  },
  methods: {
    async battery() {
      console.log("Battery");
      const { data, status } = await Request.get(`${this.$IYA.URI.AIRCRAFT__BATTERY__DASH}`);
      if (status !== this.$IYA.HTTP.OK) {
        return;
      }
      if (data.data.length < 1) {
        return;
      }
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.batteries = data.data;
    },
    async maintenance() {
      console.log("Maintenance");
      const { data, status } = await Request.get(`${this.$IYA.URI.AIRCRAFT_MAINTENANCE}`);
      if (status !== this.$IYA.HTTP.OK) {
        return;
      }
      if (data.data.length < 1) {
        return;
      }
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.drones = data.data;
    },
  },
};
</script>
